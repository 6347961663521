import * as Dialog from "@radix-ui/react-dialog";
import * as Popover from '@radix-ui/react-popover'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'

import { useAlerts } from 'hooks/useAlerts'
import { useHeader } from 'hooks/useHeader'
import { useUser } from 'hooks/useUser'

import DeleteChatPopOver from './DeleteChatPopOver'
import MessageBox from './MessageBox'

import Loading from 'components/Loading'
import {
  BsChevronLeft,
  BsChevronRight,
  BsClipboard,
  BsPeople,
  BsThreeDotsVertical,
  BsWater,
  BsWind,
  BsWindow,
} from 'react-icons/bs'
import { TbWind } from 'react-icons/tb'

import dayjs from 'dayjs'
import ChangeAlertStatusPopOver from './ChangeAlertStatusPopOver'
import OtherMessage from './OtherMessage'
import UserMessage from './UserMessage'
// import Permission from "utils/permission";
import { BiWater } from 'react-icons/bi'
import { GiGroundSprout } from 'react-icons/gi'
import { getLabelForAlerts } from 'utils/auxFunctions'
import { severityColors } from 'utils/severityColors'
import ManagerAlert from './ManagerAlert'
import MessageBoxClosed from './MessageBoxClosed'
import ObserverAlert from './ObserverAlert'
import ImagesAlertModal from "./ImagesAlertModal";

interface ShowConversationProps {
  selectedAlert: string | null
  setSelectedAlert: (uuid: string | null) => void
  isLoading: boolean
}

export default function ShowConversation({
  selectedAlert,
  setSelectedAlert,
  isLoading,
}: ShowConversationProps) {
  const [isFetching, setIsFetching] = useState(false)
  const [formattedDate, setFormattedDate] = useState<string>('')

  const { t } = useTranslation()
  const listAlertMessageDiv = useRef<HTMLDivElement>(null)

  const {
    getAlertMessages,
    getAlertByUuid,
    alertMessages,
    alert,
    setAlert,
    unreadMessages,
    alertMessagesPagination,
    getAllMembersofWorkspaceByTypeManager,
    getAllMembersofWorkspaceByTypeObserver,
  } = useAlerts()
  const { authUser } = useUser()
  const { windowSize, setShowHeader } = useHeader()

  const alertTypes = ['status_changed', 'remove_observer', 'add_observer', 'manager_changed']

  const size = windowSize()

  const navigate = useNavigate()

  const { uuidWorkspace } = useParams<{ uuidWorkspace: string }>()
  const [searchParams] = useSearchParams()
  const alertUuid = searchParams.get('alert')

  useEffect(() => {
    const fetchData = async () => {
      if (selectedAlert) {
        const data = await getAlertByUuid(selectedAlert)
        if (data) {
          setAlert(data)
        }
      }
    }

    fetchData()
  }, [selectedAlert])

  useEffect(() => {
    if (size.width && size.width < 768) {
      setShowHeader(false)
    }
  }, [size])

  useEffect(() => {
    if (!alert?.alert_datetime) return
    const formattedDate = dayjs(alert?.alert_datetime).format('MMM, DD, YYYY')
    setFormattedDate(formattedDate)
  }, [alert])

  useEffect(() => {
    if (!isLoading && listAlertMessageDiv?.current) {
      const scrollHeight = listAlertMessageDiv.current.scrollHeight
      const clientHeight = listAlertMessageDiv.current.clientHeight
      const maxScrollTop = scrollHeight - clientHeight
      listAlertMessageDiv.current.scrollTop = maxScrollTop
    }
  }, [isLoading, listAlertMessageDiv])

  useEffect(() => {
    const onScroll = async () => {
      if (
        listAlertMessageDiv?.current &&
        listAlertMessageDiv?.current?.scrollTop === 0 &&
        selectedAlert &&
        !isFetching &&
        alertMessagesPagination.current_page < alertMessagesPagination.last_page
      ) {
        setIsFetching(true)
        await getAlertMessages(
          selectedAlert,
          `?=page=1&sortBy=created_at&sortDirection=desc&page=${alertMessagesPagination.current_page + 1
          }&take=20`,
          true
        )
        listAlertMessageDiv.current.scrollTop += 60
        setIsFetching(false)
      }
    }

    listAlertMessageDiv?.current?.addEventListener('scroll', onScroll)

    return () =>
      listAlertMessageDiv?.current?.removeEventListener('scroll', onScroll)
  }, [
    alertMessagesPagination,
    listAlertMessageDiv,
    isLoading,
    selectedAlert,
    isFetching,
  ])

  const scrollToBottom = () => {
    if (listAlertMessageDiv.current) {
      listAlertMessageDiv.current.scrollTop =
        listAlertMessageDiv.current.scrollHeight
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        await Promise.all([
          getAllMembersofWorkspaceByTypeManager(
            uuidWorkspace!,
            alertUuid!,
            'manager',
            `?take=${authUser?.workspaces.license.members}`
          ),
          getAllMembersofWorkspaceByTypeObserver(
            uuidWorkspace!,
            alertUuid!,
            'observer',
            `?take=${authUser?.workspaces.license.members}`
          ),
        ])
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }
    if (alertUuid) {
      fetchData()
    }
  }, [alertUuid])

  return (
    <>
      <div className={`flex flex-col max-md:py-5 h-full`}>
        <header className='flex flex-col px-6 py-4 md:bg-[#EDEDED] gap-4 border-b border-[#EDEDED]'>
          <div className='flex justify-between items-start'>
            <div className='flex gap-3 lg:items-center max-lg:flex-col'>
              <button
                onClick={() => {
                  navigate(`/workspace/${authUser?.workspaces?.uuid}/alerts`)
                }}
                className='hidden max-md:block w-max'
              >
                <BsChevronLeft size={20} className='text-azulfy-blue' />
              </button>
              {alert?.alert_images?.length > 0 && (
                <Dialog.Root>
                  <Dialog.Trigger asChild>
                    <div className="w-[90px] max-h-[52px] relative bg-black-100 rounded-[4px] shadow-2xl group cursor-pointer">
                      <img
                        className="w-full rounded-[4px] max-h-[52px] object-contain lg:group-hover:opacity-70 transition duration-300"
                        src={alert?.alert_images?.[0]?.url}
                        alt={alert?.name}
                      />
                      <span className='absolute z-[2] top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'>
                        <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M7.99998 7.4987L3.83331 3.33203M3.83331 3.33203V6.66536M3.83331 3.33203H7.16665M13 7.4987L17.1666 3.33203M17.1666 3.33203V6.66536M17.1666 3.33203H13.8333M7.99998 12.4987L3.83331 16.6654M3.83331 16.6654V13.332M3.83331 16.6654H7.16665M13 12.4987L17.1666 16.6654M17.1666 16.6654V13.332M17.1666 16.6654H13.8333" stroke="white" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                      </span>
                    </div>
                  </Dialog.Trigger>
                  <ImagesAlertModal
                    attachments={alert?.alert_images}
                  />
                </Dialog.Root>
              )}
              <div className='flex flex-col gap-1'>
                <div className='flex items-center gap-[10px]'>
                  <span>
                    {alert?.indicators?.filter_id === 1 && (
                      <BiWater
                        className='text-base'
                        color={
                          severityColors.find(
                            (color) => color.value === alert.severity
                          )?.label
                        }
                      />
                    )}
                    {alert?.indicators?.filter_id === 2 && (
                      <TbWind
                        className='text-base'
                        color={
                          severityColors.find(
                            (color) => color.value === alert.severity
                          )?.label
                        }
                      />
                    )}
                    {alert?.indicators?.filter_id === 3 && (
                      <GiGroundSprout
                        className='text-base'
                        color={
                          severityColors.find(
                            (color) => color.value === alert.severity
                          )?.label
                        }
                      />
                    )}
                    {alert?.indicators?.filter_id === 4 && (
                      <BsPeople
                        className='text-base'
                        color={
                          severityColors.find(
                            (color) => color.value === alert.severity
                          )?.label
                        }
                      />
                    )}
                  </span>
                  <h2 className='text-azulfy-rich-black font-bold text-base'>
                    {alert?.name}
                  </h2>
                </div>
                <div className='flex items-center gap-8 max-md:mb-3'>
                  <span className='flex items-center gap-2'>
                    {alert?.category === 1 && (
                      <BsWater className='text-[#00C6FF] text-base max-lg:text-sm' />
                    )}
                    {alert?.category === 2 && (
                      <BsWater className='text-[#00C6FF] text-base max-lg:text-sm' />
                    )}
                    {alert?.category === 3 && (
                      <BsWater className='text-[#00C6FF] text-base max-lg:text-sm' />
                    )}
                    {alert?.category === 4 && (
                      <BsWind className='text-[#00C6FF] text-base max-lg:text-sm' />
                    )}
                    <p className='text-sm max-lg:text-xs font-raleway text-azulfy-rich-black'>
                      {t(getLabelForAlerts('category', alert?.category))}
                    </p>
                  </span>

                  <span className='flex items-center gap-2'>
                    <BsClipboard color={'#00C6FF'} size={16} />
                    <p className='text-sm max-lg:text-xs font-raleway text-azulfy-rich-black'>
                      {alert?.indicators?.name}
                    </p>
                  </span>

                  <span className='flex items-center gap-2'>
                    <BsWindow color={'#00C6FF'} size={16} />
                    <p className='text-sm max-lg:text-xs font-raleway text-azulfy-rich-black'>
                      {formattedDate}
                    </p>
                  </span>
                </div>
              </div>
            </div>
            <Popover.Root>
              <Popover.Trigger asChild>
                <button className='hover:opacity-70'>
                  <BsThreeDotsVertical size={24} color='#0072FF' />
                </button>
              </Popover.Trigger>
              <DeleteChatPopOver setSelectedAlert={setSelectedAlert} />
            </Popover.Root>
          </div>

          <div className='flex gap-5 w-full'>
            <div className='flex flex-col gap-1 w-1/2 lg:w-1/3'>
              <p className='text-sm text-azulfy-rich-black font-raleway'>
                Status
              </p>
              <ChangeAlertStatusPopOver />
            </div>
            <div className='lg:w-1/3 w-1/2'>
              <ManagerAlert />
            </div>
            <div className='lg:w-1/3 hidden lg:block'>
              <ObserverAlert />
            </div>
          </div>
        </header>

        {isLoading ? (
          <>
            <div className='flex items-center justify-center h-full w-full'>
              <Loading />
            </div>
          </>
        ) : (
          <>
            <main
              ref={listAlertMessageDiv}
              className='flex flex-col gap-5 p-6 overflow-y-auto h-full'
            >
              {isFetching && (
                <div className='flex items-center justify-center mt-[-20px]'>
                  <Loading />
                </div>
              )}
              {alertMessages?.length > 0 &&
                alertMessages?.map((message) => {
                  const isUserMessage =
                    message.user?.uuid === authUser?.user?.uuid
                  const sendAt = dayjs(message.created_at).format(
                    'DD/MM/YYYY HH:mm'
                  )

                  if (message.type && alertTypes.includes(message.type)) {
                    return (
                      <div
                        key={message.uuid}
                        className='flex break-all gap-x-1 items-center flex-wrap text-azulfy-grey-change-status'
                      >
                        <span>{sendAt}</span>

                        <span>-</span>

                        <div
                          className='font-raleway'
                          dangerouslySetInnerHTML={{
                            __html: message?.message,
                          }}
                        />
                      </div>
                    )
                  }

                  if (!isUserMessage) {
                    return (
                      <OtherMessage
                        key={message.uuid}
                        message={message}
                        sendAt={sendAt}
                      />
                    )
                  } else {
                    return (
                      <UserMessage
                        key={message.uuid}
                        message={message}
                        sendAt={sendAt}
                      />
                    )
                  }
                })}

              {unreadMessages?.length > 0 && (
                <div className='flex items-center w-full gap-[10px]'>
                  <span className='h-[1px] w-full bg-azulfy-blue' />
                  <p className='min-w-[130px] text-xs text-azulfy-blue text-center font-semibold font-raleway'>
                    {t('new_messages')}
                  </p>
                  <span className='h-[1px] w-full bg-azulfy-blue' />
                </div>
              )}

              {unreadMessages?.length > 0 &&
                unreadMessages?.map((message) => {
                  const isUserMessage =
                    message.user?.uuid === authUser?.user?.uuid
                  const sendAt = dayjs(message.created_at).format(
                    'DD/MM/YYYY HH:mm'
                  )
                  if (message.type === 'status_changed') {
                    return (
                      <div
                        key={message.uuid}
                        className='flex items-center gap-1 text-azulfy-grey-change-status'
                      >
                        <BsChevronRight size={10} />
                        <span
                          className=' font-raleway text-xs'
                          dangerouslySetInnerHTML={{
                            __html: message?.message,
                          }}
                        />
                      </div>
                    )
                  }
                  if (!isUserMessage) {
                    return (
                      <OtherMessage
                        key={message.uuid}
                        message={message}
                        sendAt={sendAt}
                      />
                    )
                  } else {
                    return (
                      <UserMessage
                        key={message.uuid}
                        message={message}
                        sendAt={sendAt}
                      />
                    )
                  }
                })}
            </main>
          </>
        )}

        {/* <Permission permissions={['create message alerts']}> */}
        <>
          {alert?.status?.type !== 'closed' ? (
            <>
              <MessageBox scrollToBottom={scrollToBottom} />
            </>
          ) : (
            <>
              <MessageBoxClosed />
            </>
          )}
        </>
        {/* </Permission> */}
      </div>
    </>
  )
}
