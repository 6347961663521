import Map from "components/MapComponent";
import MapFilter from "components/MapComponent/internals/addOns/components/MapFilter";
import MapLegend from "components/MapComponent/internals/addOns/components/MapLegend";
import MapRasterFilter from "components/MapComponent/internals/addOns/components/MapRasterFilter";
import useMapMakers from "components/MapComponent/internals/addOns/hooks/useMapMarkers";
import { Alert } from "hooks/useAlerts";
import { FilterProps, IndicatorsProps } from "hooks/useFilters";
import { useIndicatorsHistory } from "hooks/useIndicatorsHistory";
import { useLives } from "hooks/useLives";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import Permission from "utils/permission";

export interface FeaturePoint {
  type: string;
  geometry: Geometry;
  properties: Properties;
}

export interface Geometry {
  type: string;
  coordinates: number[];
}

export interface Properties {
  name: string;
  uuid: string;
  type: number;
  severity?: number;
  indicators: IndicatorsProps;
  alert_datetime: string;
  status: {
    name: {
      en: string;
      pt: string;
    };
    uuid: string;
    type: string;
  }
}

const HomeAdminMap = ({
  markRedirectUrl,
  alerts,
}: {
  markRedirectUrl?: string;
  alerts?: Alert[];
}) => {
  const mapRef = useRef<mapboxgl.Map | null>(null);
  const drawRef = useRef<MapboxDraw | null>(null);
  const { getAllLivesCams, liveCams } = useLives();
  const { setWhereSelected, setWhatSelected, setWhenSelected } = useIndicatorsHistory(); 
  const { uuidWorkspace } = useParams<{ uuidWorkspace: string }>()

  const hasPermission = Permission({
    permissions: ['view workspace video'],
    children: <></>,
  });

  async function handleGetAllLivesCams(workspaceUuid: string) {
    await getAllLivesCams(workspaceUuid);
  }

  // useEffect(() => {
  //   if(alerts){
  //     setAlerts(alerts);
  //   }
  // }, [alerts])

  useEffect(() => {
    if(hasPermission){
      handleGetAllLivesCams(uuidWorkspace!);
    }
  }, [uuidWorkspace])

  const [where, setWhere] = useState<FilterProps | null>(
    null
  );

  const [what, setWhat] = useState<IndicatorsProps | null>(
    null
  );

  const [when, setWhen] = useState<{
    startDate: Date;
    endDate: Date;
    label: string;
  } | null>(null);

  let filteredAlerts: Alert[] = alerts || [];

  useEffect(() => {
    if (where != null) {
      setWhereSelected(where);
    }
  
    if (what != null) {
      setWhatSelected(what);
    }
    
    if (when != null) {
      setWhenSelected(when);
    }
  }, [where, what, when]);

  
  const features: FeaturePoint[] = filteredAlerts?.map((alert) => ({
    type: "Feature",
    geometry: {
      type: "Point",
      coordinates: [alert.lng, alert.lat],
    },
    properties: {
      name: alert.name,
      uuid: alert.uuid,
      type: alert.indicators.filter_id,
      severity: alert.severity,
      status: alert.status,
      indicator: alert.indicator,
      alert_datetime: new Date(alert.alert_datetime).toLocaleDateString("pt-BR"),
      indicators: alert.indicators     
    },
  })) as never ?? [];

  const liveCamFeatures = liveCams.map((liveCam) => ({
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates: [
        liveCam.region_map_area.geometry.coordinates[0],
        liveCam.region_map_area.geometry.coordinates[1],
      ],
    },
    properties: {
      name: liveCam.name,
      uuid: liveCam.uuid,
      type: 55,
    },
  }));

  const allFeatures = [...features, ...liveCamFeatures];

  useMapMakers({
    mapRef,
    markRedirectUrl,
    features: hasPermission ? allFeatures as FeaturePoint[] : features,
  });

  return (
    <Map showSearchBar={false} showNotification={false} mapRef={mapRef} drawRef={drawRef}>
      <MapFilter
        mapRef={mapRef}
        canDraw={true}
        drawRef={drawRef}
        whereSelected={(where) => {
          setWhere(where);
        }}
        whatSelected={(what) => {
          setWhat(what);
        }}
        whenSelected={(when) => {
          setWhen(when);
        }}
        handleClear={() => {
          // setWhere(null);
          // setWhat(null);
          setWhen(null);
        }}
      />
      <MapLegend />
      <MapRasterFilter mapRef={mapRef} alerts={filteredAlerts} />
    </Map>
  );
};

export default HomeAdminMap;
