import * as Popover from "@radix-ui/react-popover";
import { ButtonFilter } from "components/ButtonFilter";
import Map from "components/MapComponent";
import dayjs from "dayjs";
import { useAlerts } from "hooks/useAlerts";
import { FilterProps, IndicatorsProps, useFilters } from "hooks/useFilters";
import { useIndicatorsHistory } from "hooks/useIndicatorsHistory";
import { t } from "i18next";
import WhatPopover from "pages/Chat/components/ListAlerts/WhatPopover";
import WhenPopover from "pages/Chat/components/ListAlerts/WhenPopover";
import WherePopover from "pages/Chat/components/ListAlerts/WherePopover";
import { MutableRefObject, useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";

//@ts-ignore
const MapFilter = ({ mapRef, canDraw, drawRef, whereSelected, whatSelected, whenSelected, handleClear }: {
  mapRef: MutableRefObject<mapboxgl.Map | null>;
  canDraw: boolean;
  drawRef: MutableRefObject<MapboxDraw | null>;
  whereSelected: (where: FilterProps | null) => void;
  whatSelected: (what: IndicatorsProps | null) => void;
  whenSelected: (when: { startDate: Date; endDate: Date; label: string } | null) => void;
  handleClear: () => void;
}) => {
  const { filters } = useFilters();
  const { getIndicatorsHistory, setWhatSelected, setWhereSelected, setWhenSelected, setShowGraph, setIndicatorsHistory } = useIndicatorsHistory();
  const { getAlerts, setSelectedAlert } = useAlerts();
  //@ts-ignore
  const [isLoading, setIsLoading] = useState(false);
  //@ts-ignore
  const [type, setType] = useState<"all" | "deleted">("all");
  //@ts-ignore
  const [search, setSearch] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();

  const newDate = dayjs();
  const lastMonth = dayjs().subtract(29, "days");

  const [where, setWhere] = useState<FilterProps | null | undefined>(
    null
  );
  const [what, setWhat] = useState<IndicatorsProps | null | undefined>(
    null
  );
  const [when, setWhen] = useState<{
    startDate: Date;
    endDate: Date;
    label: string;
  } | null>({
    startDate: lastMonth.toDate(),
    endDate: newDate.toDate(),
    label: `${lastMonth.format("DD/MM/YYYY")} - ${newDate.format("DD/MM/YYYY")}`,
  });

  const [whenApplied, setWhenApplied] = useState<{
    startDate: Date;
    endDate: Date;
    label: string;
  } | null>({
    startDate: lastMonth.toDate(),
    endDate: newDate.toDate(),
    label: `${lastMonth.format("DD/MM/YYYY")} - ${newDate.format("DD/MM/YYYY")}`,
  });

  const { uuidWorkspace } = useParams<{ uuidWorkspace: string }>();

  useEffect(() => {
    if (whenApplied) {
      const startDateTimestamp = dayjs(whenApplied.startDate).unix();
      const endDateTimestamp = dayjs(whenApplied.endDate).unix();

      searchParams.set("startDate", startDateTimestamp.toString());
      searchParams.set("endDate", endDateTimestamp.toString());
      setSearchParams(searchParams);
    }

  }, [whenApplied]);

  useEffect(() => {
    const whereParam = searchParams.get("where");
    const whatParam = searchParams.get("what");
    const startDateParam = searchParams.get("startDate");
    const endDateParam = searchParams.get("endDate");

    if (whereParam) {
      const whereFilter = filters?.find((filter) => filter.id === Number(whereParam));
      if (whereFilter) {
        setWhere(whereFilter);
        whereSelected(whereFilter);
      }
    }

    if (whatParam) {
      const whatFilter = where?.indicators?.find((indicator) => indicator.id === Number(whatParam));
      if (whatFilter && where) {
        setWhat(whatFilter);
        whatSelected(whatFilter);
      }
    }

    if (startDateParam && endDateParam) {
      const startDate = new Date(Number(startDateParam) * 1000);
      const endDate = new Date(Number(endDateParam) * 1000);
      const label = `${dayjs(startDate).format("DD/MM/YYYY")} - ${dayjs(endDate).format("DD/MM/YYYY")}`;
      setWhen({ startDate, endDate, label });
      whenSelected({ startDate, endDate, label });
    }
  }, [searchParams, filters, where, what]);

  async function handleApply() {
    setIsLoading(true);
    setSelectedAlert(null);

    const startDate = dayjs(when?.startDate).unix();
    const endDate = dayjs(when?.endDate).unix();

    if (where && what) {
      await getIndicatorsHistory(uuidWorkspace!, `?where=${where.id}&what=${what.id}&lt=${startDate}&gt=${endDate}`);
    }

    setWhenApplied(when);

    if (when) {
      await getAlerts(
        `?page=1&sortBy=alert_datetime&sortDirection=desc&take=5000${type === "all" ? "" : "&trashed=1"
        }${where ? `&where=${where.id}` : ""}${what ? `&what=${what.id}` : ""
        }&search=${search}&lt=${dayjs(when?.startDate).unix()}&gt=${dayjs(when?.endDate).unix()}`
      );
    } else {
      await getAlerts(
        `?page=1&sortBy=alert_datetime&sortDirection=desc&take=5000${type === "all" ? "" : "&trashed=1"
        }${where ? `&where=${where.id}` : ""}${what ? `&what=${what.id}` : ""
        }&search=${search}`
      );
    }

    setIsLoading(false);
  }

  async function handleClearWhen() {
    setWhen(null);
    setWhenApplied(null);
    await getAlerts(
      `?page=1&sortBy=alert_datetime&sortDirection=desc`
    )
  }

  function handleClearAll() {
    setShowGraph(true);
    setWhereSelected(null);
    setWhatSelected(null);
    setWhenSelected(null);
    setWhere(null);
    setWhat(null);
    setWhen(null);
    setWhenApplied(null);
    setIndicatorsHistory(null);
    setSearchParams({});
  }

  return (
    <Map.Item className="left-4 top-4">
      <div className="flex-wrap items-center justify-center w-full px-5 mb-2 flex gap-x-3 gap-y-2" id="filter-map-popover">
        <Popover.Root>
          <Popover.Trigger asChild>
            <ButtonFilter text={where ? t(where.name) : where === undefined ? t("all") : t("where")} />
          </Popover.Trigger>

          <WherePopover
            where={where}
            setWhere={(newWhere) => {
              if (newWhere) {
                searchParams.set("where", newWhere.id.toString());
              } else {
                searchParams.delete("where");
                searchParams.delete("what");
              }
              setSearchParams(searchParams);
              setWhere(newWhere);
              setWhat(null);
              setWhereSelected(newWhere as FilterProps);
              whereSelected(newWhere as FilterProps);
            }}
          />
        </Popover.Root>

        <Popover.Root>
          <Popover.Trigger asChild>
            <ButtonFilter text={what ? t(what.name) : what === undefined ? t("all") : t("what")} />
          </Popover.Trigger>

          <WhatPopover
            what={what}
            setWhat={(newWhat) => {
              if (newWhat) {
                searchParams.set("what", newWhat.id.toString());
              } else {
                searchParams.delete("what");
              }
              setSearchParams(searchParams);
              setWhat(newWhat);
              // setWhen(null);
              // setWhenApplied(null)
              setWhatSelected(newWhat as IndicatorsProps);
              whatSelected(newWhat as IndicatorsProps);
            }}
            whereSelected={where && where.indicators!}
          />
        </Popover.Root>

        <Popover.Root>
          <Popover.Trigger asChild>
            <ButtonFilter text={whenApplied ? t(whenApplied.label) : t("when")} />
          </Popover.Trigger>

          <WhenPopover
            when={when}
            setWhen={(newWhen) => {
              setWhen(newWhen);
              whenSelected(newWhen as { startDate: Date; endDate: Date; label: string });
            }}
            handleApply={handleApply}
            handleClear={() => {
              handleClearWhen();
              handleClear();
            }}
          />
        </Popover.Root>

        <button
          disabled={!where && where !== undefined}
          className={`text-xs font-comfortaa font-bold text-azulfy-blue p-3 shadow-lg rounded-md bg-white hover:opacity-80 ${!where && where !== undefined && "hidden"}`}
          onClick={() => {
            handleClearAll()
          }}
        >
          {t("clear_all")}
        </button>
      </div>
    </Map.Item>
  );
};

export default MapFilter;
