import * as Popover from "@radix-ui/react-popover";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";

import { Alert, useAlerts } from "hooks/useAlerts";

import { SearchInput } from "components/SearchInput";

import { ButtonFilter } from "components/ButtonFilter";
import Loading from "components/Loading";
import NoAlerts from "components/NoAlerts";
import dayjs from "dayjs";
import { FilterProps, IndicatorsProps, useFilters } from "hooks/useFilters";
import { useHeader } from "hooks/useHeader";
import { useUser } from "hooks/useUser";
import { BiWater } from "react-icons/bi";
import { BsChevronRight, BsPeople } from "react-icons/bs";
import { GiGroundSprout } from "react-icons/gi";
import { TbWind } from "react-icons/tb";
import { getAlertStatuses } from "../AlertStatuses";
import WhatPopover from "./WhatPopover";
import WhenPopover from "./WhenPopover";
import WherePopover from "./WherePopover";

interface ListAlertsProps {
  selectedAlert: string | null;
  setSelectedAlert: (uuid: string | null) => void;
}

export default function ListAlerts({ selectedAlert, setSelectedAlert }: ListAlertsProps) {
  const { t } = useTranslation();
  const { authUser } = useUser();
  const { filters } = useFilters();
  const [isLoading, setIsLoading] = useState(false);
  // const [loadingMore, setLoadingMore] = useState(false);
  const [search, setSearch] = useState("");
  const [inicialized, setInitialized] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const [isTyping, setIsTyping] = useState(false);
  const [type, setType] = useState<"all" | "deleted">("all");
  const [where, setWhere] = useState<FilterProps | null | undefined>(
    null
  );
  const [what, setWhat] = useState<IndicatorsProps | null | undefined>(
    null
  );
  const [when, setWhen] = useState<{
    startDate: Date;
    endDate: Date;
    label: string;
  } | null>(null);

  const [whenApplied, setWhenApplied] = useState<{
    startDate: Date;
    endDate: Date;
    label: string;
  } | null>(null);

  const startDateParam = searchParams.get("startDate");
  const endDateParam = searchParams.get("endDate");
  const whereParam = searchParams.get("where");
  const whatParam = searchParams.get("what");

  const statuses = getAlertStatuses();

  const { getAlertsTemp, alertsTemp, setAlertsTemp, setAlertsFiltered } = useAlerts();
  const { setShowHeader } = useHeader();

  const listAlertDiv = useRef<HTMLDivElement>(null);
  const alertRefs = useRef<Record<string, HTMLButtonElement | null>>({});

  const navigate = useNavigate();

  async function getAllAlerts() {
    setIsLoading(true);
    await getAlertsTemp(
      `?page=1&take=5000&sortBy=alert_datetime&sortDirection=desc${type === "all" ? "" : "&closed=1"
      }${where ? `&where=${where.id}` : ""}${what ? `&what=${what.id}` : ""
      }&search=${search}&lt=${whenApplied
        ? `${dayjs(whenApplied?.startDate).unix()}&gt=${dayjs(
          whenApplied?.endDate
        ).unix()}`
        : ``
      }`
    );
    setIsLoading(false);
  }

  async function getAllAlertsParams(whereId: string, whatId: string) {
    setIsLoading(true);
    await getAlertsTemp(
      `?page=1&take=5000&sortBy=alert_datetime&sortDirection=desc${type === "all" ? "" : "&closed=1"
      }${whereId ? `&where=${whereId}` : ""}${whatId ? `&what=${whatId}` : ""
      }&search=${search}&lt=${whenApplied
        ? `${dayjs(whenApplied?.startDate).unix()}&gt=${dayjs(
          whenApplied?.endDate
        ).unix()}`
        : ``
      }`
    );
    setIsLoading(false);
  }

  useEffect(() => {
    if (!isLoading && alertRefs.current) {
      if (selectedAlert && alertRefs.current[selectedAlert]) {
        const selectedElement = alertRefs.current[selectedAlert];
        if (selectedElement && inicialized) {
          selectedElement.scrollIntoView({
            behavior: "smooth",
          });
        }
      }
    }
  }, [isLoading, selectedAlert]);

  useEffect(() => {
    setShowHeader(true);
  }, []);

  async function handleSelectAlert(alert: Alert) {
    setInitialized(false);
    const alertIndex = alertsTemp.indexOf(alert);
    if (alertIndex > -1) {
      alertsTemp[alertIndex].total_unread_messages = 0;
      setAlertsTemp([...alertsTemp]);
    }
    searchParams.set("alert", alert?.uuid || "");
    setSelectedAlert(alert?.uuid || "");
    navigate(
      `/workspace/${authUser?.workspaces.uuid}/alerts?${searchParams.toString()}`
    );
  }

  useEffect(() => {
    const delayDebounce = setTimeout(async () => {
      if (!isTyping) return;
      await getAllAlerts();
      setIsTyping(false);
    }, 300);
    return () => clearTimeout(delayDebounce);
  }, [search, isTyping]);

  async function handleApply() {
    setIsLoading(true);
    setWhenApplied(when);
    if (when) {
      await getAlertsTemp(
        `?page=1&take=5000&sortBy=alert_datetime&sortDirection=desc${type === "all" ? "" : "&closed=1"
        }${where ? `&where=${where.id}` : ""}${what ? `&what=${what.id}` : ""
        }&search=${search}&lt=${dayjs(when?.startDate).unix()}&gt=${dayjs(when?.endDate).unix()}`
      );
    } else {
      await getAlertsTemp(
        `?page=1&sortBy=alert_datetime&sortDirection=desc${type === "all" ? "" : "&closed=1"
        }${where ? `&where=${where.id}` : ""}${what ? `&what=${what.id}` : ""
        }&search=${search}`
      );
    }
    setIsLoading(false);
  }

  async function handleClear() {
    setIsLoading(true);
    setWhen(null);
    setWhenApplied(null);
    setAlertsFiltered(null);
    searchParams.delete("startDate");
    searchParams.delete("endDate");
    setSearchParams(searchParams);
    await getAlertsTemp(
      `?page=1&take=5000&sortBy=alert_datetime&sortDirection=desc${type === "all" ? "" : "&closed=1"
      }${where ? `&where=${where.id}` : ""}${what ? `&what=${what.id}` : ""
      }&search=${search}`
    );
    setIsLoading(false);
  }

  useEffect(() => {
    if (where == null) {
      setWhat(null);
    }
  }, [where]);

  useEffect(() => {
    if (whereParam) {
      const whereFilter = filters?.find((filter) => filter.id === Number(whereParam));
      if (whereFilter) {
        setWhere(whereFilter);
      }
    } else {
      setWhere(null);
    }

    if (whatParam) {
      const whatFilter = where?.indicators?.find((indicator) => indicator.id === Number(whatParam));
      if (whatFilter && where) {
        setWhat(whatFilter);
      }
    } else {
      setWhat(null);
    }

    if (startDateParam && endDateParam) {
      const startDate = new Date(Number(startDateParam) * 1000);
      const endDate = new Date(Number(endDateParam) * 1000);
      const label = `${dayjs(startDate).format("DD/MM/YYYY")} - ${dayjs(endDate).format("DD/MM/YYYY")}`;
      setWhen({ startDate, endDate, label });
      setWhenApplied({ startDate, endDate, label });
    }
  }, [filters]);

  useEffect(() => {
    if (where !== null && what !== null && whenApplied !== null) {
      getAllAlerts();
    }
  }, [where, what, whenApplied]);

  useEffect(() => {
    if (!whereParam && !whatParam && !startDateParam && !endDateParam) {
      getAllAlertsParams("", "");
    }
  }, [])

  useEffect(() => {
    const whenAppliedDebounce = setTimeout(async () => {
      if (whenApplied) {
        await getAllAlerts();
      }
    })
    return () => clearTimeout(whenAppliedDebounce);
  }, [whenApplied])

  return (
    <div className="flex flex-col items-center py-4 gap-4 h-full max-h-full">
      <div className="hidden max-md:flex items-center justify-center w-full px-3 mb-2">
        <SearchInput
          placeholder={`${t("search")}...`}
          showSearchIcon
          value={search}
          onChange={(e) => {
            setIsTyping(true);
            setSearch(e.target.value);
          }}
          classNamesInput="max-md:text-sm"
          classNamesDiv="w-full px-4 py-3"
        />
      </div>
      <div className="hidden max-md:flex flex-wrap items-center justify-center gap-x-3 gap-y-2 w-full px-3 mb-2">
        <Popover.Root>
          <Popover.Trigger asChild>
            <ButtonFilter text={t(where?.name.toLowerCase() || "") || t("where")} />
          </Popover.Trigger>
          <WherePopover
            where={where}
            setWhere={(newWhere) => {
              setWhat(null);
              if (newWhere) {
                searchParams.set("where", newWhere.id.toString());
              } else {
                searchParams.delete("where");
                searchParams.delete("what");
              }
              getAllAlertsParams(newWhere ? String(newWhere?.id) : "", "");
              setSearchParams(searchParams);
              setWhere(newWhere);
            }}
          />
        </Popover.Root>
        <Popover.Root>
          <Popover.Trigger asChild>
            <ButtonFilter text={t(what?.name || "") || t("what")} />
          </Popover.Trigger>
          <WhatPopover
            what={what}
            setWhat={(newWhat) => {
              setWhat(newWhat);
              if (newWhat) {
                searchParams.set("what", newWhat.id.toString());
              } else {
                searchParams.delete("what");
              }
              setSearchParams(searchParams);
              getAllAlertsParams(where ? String(where?.id) : "", newWhat ? String(newWhat?.id) : "");
            }}
            whereSelected={where?.indicators!}
          />
        </Popover.Root>
        <Popover.Root>
          <Popover.Trigger asChild>
            <ButtonFilter text={t(whenApplied?.label || "") || t("when")} />
          </Popover.Trigger>
          <WhenPopover
            when={when}
            setWhen={(newWhen) => {
              setWhen(newWhen);
              if (newWhen) {
                //@ts-ignore
                searchParams.set("startDate", dayjs(newWhen.startDate).unix().toString());
                //@ts-ignore
                searchParams.set("endDate", dayjs(newWhen.endDate).unix().toString());
              } else {
                searchParams.delete("startDate");
                searchParams.delete("endDate");
              }
              setSearchParams(searchParams);
            }}
            handleApply={handleApply}
            handleClear={handleClear}
          />
        </Popover.Root>
      </div>
      <h3 className="font-bold text-azulfy-blue max-md:text-xl">
        ({alertsTemp.length}) {alertsTemp.length === 1 ? t("chat_alert") : t("chat_alerts")}
      </h3>
      <div className="grid grid-cols-2 w-full">
        <button
          onClick={() => {
            setType("all");
          }}
          className={`py-[5px] text-azulfy-blue font-bold text-xs ${type === "all" ? "border-b-2 border-azulfy-blue" : ""
            }`}
        >
          {t("all")}
        </button>
        <button
          onClick={() => {
            setType("deleted");
          }}
          className={`py-[5px] text-azulfy-blue font-bold text-xs ${type === "deleted" ? "border-b-2 border-azulfy-blue" : ""
            }`}
        >
          {t("closed")}
        </button>
      </div>
      <div className="hidden md:flex items-center justify-center w-full px-3">
        <SearchInput
          placeholder={`${t("search")}...`}
          value={search}
          onChange={(e) => {
            setIsTyping(true);
            setSearch(e.target.value);
          }}
          showSearchIcon
          classNamesInput="max-lg:text-xs"
        />
      </div>
      <div className="hidden md:flex flex-wrap items-center justify-center gap-x-3 gap-y-2 w-full px-5 mb-2">
        <Popover.Root>
          <Popover.Trigger asChild>
            <ButtonFilter text={t(where?.name.toLowerCase() || "") || t("where")} />
          </Popover.Trigger>
          <WherePopover
            where={where}
            setWhere={(newWhere) => {
              setWhat(null);
              if (newWhere) {
                searchParams.set("where", newWhere.id.toString());
              } else {
                searchParams.delete("where");
                searchParams.delete("what");
              }
              getAllAlertsParams(newWhere ? String(newWhere?.id) : "", "");
              setSearchParams(searchParams);
              setWhere(newWhere);
            }}
          />
        </Popover.Root>
        <Popover.Root>
          <Popover.Trigger asChild>
            <ButtonFilter text={t(what?.name || "") || t("what")} />
          </Popover.Trigger>
          <WhatPopover
            what={what}
            setWhat={(newWhat) => {
              setWhat(newWhat);
              if (newWhat) {
                searchParams.set("what", newWhat.id.toString());
              } else {
                searchParams.delete("what");
              }
              setSearchParams(searchParams);
              getAllAlertsParams(where ? String(where?.id) : "", newWhat ? String(newWhat?.id) : "");
            }}
            whereSelected={where?.indicators!}
          />
        </Popover.Root>
        <Popover.Root>
          <Popover.Trigger asChild>
            <ButtonFilter text={t(whenApplied?.label || "") || t("when")} />
          </Popover.Trigger>
          <WhenPopover
            when={when}
            setWhen={(newWhen) => {
              setWhen(newWhen);
              if (newWhen) {
                //@ts-ignore
                searchParams.set("startDate", dayjs(newWhen.startDate).unix().toString());
                //@ts-ignore
                searchParams.set("endDate", dayjs(newWhen.endDate).unix().toString());
              } else {
                searchParams.delete("startDate");
                searchParams.delete("endDate");
              }
              setSearchParams(searchParams);
            }}
            handleApply={handleApply}
            handleClear={handleClear}
          />
        </Popover.Root>
      </div>

      <div
        ref={listAlertDiv}
        className="flex flex-col w-full text-azulfy-rich-black h-full overflow-y-auto overflow-x-hidden"
      >
        {isLoading ? (
          <div className="flex h-full items-center justify-center">
            <Loading />
          </div>
        ) : (
          <>
            {alertsTemp?.length === 0 && (
              <div className="py-10">
                <NoAlerts />
              </div>
            )}
            {alertsTemp?.length > 0 &&
              alertsTemp?.map((alert) => {
                const dateFormatted = dayjs(alert.alert_datetime).format(
                  "DD/MM/YYYY"
                );
                const isSelected = selectedAlert === alert.uuid;
                return (
                  <button
                    onClick={() => handleSelectAlert(alert)}
                    key={alert.uuid}
                    ref={(el) => (alertRefs.current[alert.uuid] = el)}
                    className={`flex items-center gap-2 justify-between w-full p-3 border-b border-grey-400 border-opacity-50 ${isSelected ? "bg-azulfy-blue" : ""
                      }`}
                  >
                    <div className="flex items-start gap-[10px] w-full">
                      <span
                        className={`rounded-full bg-white"
                          }`}
                      >
                        {alert?.indicators.filter_id === 1 && (
                          <BiWater
                            color={isSelected ? "#fff" : "#0072FF"}
                            size={30}
                          />
                        )}
                        {alert?.indicators.filter_id === 2 && (
                          <TbWind
                            color={"#F3C947"}
                            size={30}
                          />
                        )}

                        {alert?.indicators.filter_id === 3 && (
                          <GiGroundSprout
                            color={"#27AE60"}
                            size={30}
                          />
                        )}

                        {alert?.indicators.filter_id === 4 && (
                          <BsPeople
                            color={isSelected ? "#fff" : "#0072FF"}
                            size={30}
                          />
                        )}
                      </span>
                      <div className="flex flex-col items-start gap-1 w-full">
                        <h3
                          className={`text-sm max-lg:text-xs font-semibold text-start ${isSelected ? "text-white" : ""
                            }`}
                        >
                          {alert.name}
                        </h3>
                        <span
                          className={`max-lg:text-xs line-clamp-1 text-start ${isSelected ? "text-white" : ""
                            }`}
                        >
                          {alert.indicators.name}
                        </span>
                        <div className="flex items-center justify-between w-full">
                          <span
                            className={`max-lg:text-[10px] text-xs font-bold font-raleway text-start line-clamp-1 ${isSelected ? "text-white" : ""
                              }`}
                          >
                            {statuses.find((status) => status.value === alert?.status?.type)?.label}
                          </span>
                          <span
                            className={`max-lg:text-[10px] text-xs font-raleway ml-3 ${isSelected ? "text-white" : ""
                              }`}
                          >
                            {dateFormatted}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="flex items-center gap-1">
                      {alert.total_unread_messages > 0 && (
                        <span className="bg-[#FC6530] rounded-full w-4 h-4 max-lg:w-3 max-lg:h-3 text-white text-[8px] max-lg:text-[7px] font-semibold py-[3px] max-lg:py-[2px] px-[1px] text-center">
                          {alert.total_unread_messages}
                        </span>
                      )}
                      <span className="hover:opacity-70">
                        <BsChevronRight
                          size={12}
                          className={"text-azulfy-rich-black"}
                        />
                      </span>
                    </div>
                  </button>
                );
              })}
          </>
        )}
        {/* {loadingMore && (
          <div className="flex items-center justify-center py-2">
            <Loading />
          </div>
        )} */}
      </div>
    </div>
  );
}
